li.buildSummary {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px rgb(231, 231, 231) solid;
  position: relative;
  font-size: 13px;
  user-select: none;
  -webkit-user-select: none;
  cursor: default;
}

li.buildSummary:hover {
  background: rgb(245, 245, 245);
}

li.buildSummary .content {
  margin-left: 10px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

li.buildSummary .content:last-of-type {
  margin-right: 10px;
}

li.buildSummary .rightInformation {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

li.buildSummary .avatarImage img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

li.buildSummary .sourceImage img {
  width: 15px;
  height: 15px;
}

li.buildSummary .createdAt {
  color: gray;
}

li.buildSummary .source-number {
  color: gray;
  font-weight: bold;
  margin-right: 10px;
}

li.buildSummary h1 {
  font-size: 13px;
  font-weight: 400;
  margin: 20px 0;
}

li.buildSummary h1 a {
  color: black;
}

li.buildSummary h2 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 5px;
}

li.buildSummary h2 a {
  color: black;
  font-weight: normal;
}

li.buildSummary h4 {
  font-size: 14px;
  font-weight: bold;
  color: #a7a7a7;
  margin: 0;
  margin-bottom: 5px;
}

p.detail {
  font-size: 12px;
  color: #586069;
}

li.buildSummary .contentBlock {
  margin-bottom: 20px;
}

li.buildSummary .commitDetails {
  margin-left: 25px;
}

.commitDetails h2 {
  font-size: 16px;
}

.commitDetails h4 {
  margin-bottom: 5px;
}

.pullRequestDetails h4 {
  margin-left: 25px;
}

li.buildSummary .header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

li.buildSummary .linearHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 20px 0;
}

li.buildSummary .linearHeader h1 {
  margin: 0;
}

li.buildSummary .linearHeader img {
  margin-top: 5px;
}

li.buildSummary .title {
  margin: auto;
}

li.buildSummary p {
  margin: 0;
}

li.buildSummary .build-links a {
  margin: 0 10px;
}

li.buildSummary .build-links {
  margin-left: auto;
}

.pullRequestBody {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

li.buildSummary div.pullRequestBody img {
  margin-top: 5px;
}

li.buildSummary .buildLink {
  background: red;
  height: 48px;
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 400;
}

li.buildSummary .deleteBuild img {
  margin: 0;
}

li.buildSummary .deleteBuild {
  padding: 20px;
  box-sizing: initial;
}
