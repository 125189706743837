div.details {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px rgb(231, 231, 231) solid;
  position: relative;
}

div.details h1 {
  font-size: 20px;
  margin: 20px 0;
}

div.details h4 {
  font-size: 14px;
  font-weight: bold;
  color: #a7a7a7;
  margin: 0;
  margin-bottom: 5px;
}

div.details h2 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 5px;
}

div.details h2 a {
  color: black;
  font-weight: normal;
}

div.details h1 a {
  color: black;
  font-weight: bold;
}

div.details a {
  padding: 0;
  text-decoration: none;
}

div.details p.detail {
  font-size: 12px;
  color: #586069;
}

div.details img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

div.details .contentBlock {
  margin-bottom: 20px;
}

div.details .commitDetails {
  margin-left: 25px;
}

.commitDetails h2 {
  font-size: 16px;
}

.commitDetails h4 {
  margin-bottom: 5px;
}

.pullRequestDetails h4 {
  margin-left: 25px;
}

div.details .header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.details .linearHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 20px 0;
}

div.details .linearHeader h1 {
  margin: 0;
}

div.details .linearHeader img {
  margin-top: 5px;
}

div.details .title {
  margin: auto;
}

div.details p {
  margin: 0;
}

div.details .build-links a {
  margin: 0 10px;
}

div.details .build-links {
  margin-left: auto;
}

.pullRequestBody {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

div.details .pullRequestDetails {
  margin-left: 25px;
}

div.details div.pullRequestBody img {
  margin-top: 5px;
}

div.details .buildLink {
  background: red;
  height: 48px;
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 400;
}

div.details .deleteBuild img {
  margin: 0;
}

div.details .deleteBuild {
  padding: 20px;
  text-align: center;
}

#backButton {
  padding: 10px;
  user-select: none;
  cursor: default;
}

#backButton span {
  margin-left: 10px;
}

#backButton:hover span {
  color: black;
}
